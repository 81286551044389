<template>
  <div v-if="loaded">
    <CampusHeader
        :show-mobile="false"
        :show-campus="false"
        :can-create="true"
        :activeTab="0"
        @start-creating="goToCreate"
        :create-text="'Add New'"
        :manage-campuses="false"
        :can-select="false"
        :header-text="headerText"
        :header_tabs="[{title:'Applications List'}]"
    ></CampusHeader>
    <section class="section pt-4 box">
      <div class=" columns">
        <div class="column">
          <!-- Main container -->
          <div v-for="application in applications" :key="application.id">
            <!-- Left side -->
            <div class="level mb-2">
              <div class="level-left">
                <div class="level-item">
                  <p>
                    <router-link :to="`/g/my-wards/${application.user.id}`"><span class="is-size-4">{{
                        application.user.first_name
                      }} {{ application.user.last_name }}</span></router-link>
                    @
                    <span class="is-size-4">{{ application.campus.name }}</span> phase: <span
                      class="is-size-4">{{ application.phase.long_name }}</span>
                    stage: <span class="is-size-4">{{ application.stage.long_name }}</span></p>
                </div>
              </div>

              <!-- Right side -->
              <div class="level-right">
                <p class="level-item has-background-primary tag is-size-6 has-text-white">{{
                    application.status.name
                  }} </p>

              </div>
            </div>
            <p class="mb-2">Made: {{ application.age_human_readable }}</p>

            <b-progress
                class="is-hidden-touch"
                type="is-primary"
                show-value
                size="is-medium"
                :max="10*stages.length"
            >
              <b-progress-bar v-for="stage in stages" slot="bar"

                              :type="{'is-light':stage.order>application.application_stage.order,'is-primary':stage.order<=application.application_stage.order}"
                              :key="stage.id"
                              :value="10">
                <div><p>{{ stage.name }}</p></div>

              </b-progress-bar>

            </b-progress>
            <div class="is-hidden-desktop">
              <p>Current Stage:</p>
              <p class="pb-4 has-text-weight-semibold is-size-3">{{ application.application_stage.name }}</p>
            </div>
            <div>
              <p>{{ stage_based_text(application.application_stage) }}</p>
            </div>
          </div>
        </div>
      </div>
    </section>


  </div>
</template>

<script>
import Application from "@/models/Application";
import CampusHeader from "@/components/panelled-dash/CampusHeader";
import User from "@/models/User";
import ApplicationStage from "@/models/ApplicationStage";
import ApplicationStatus from "@/models/ApplicationStatus";

export default {
  name: 'GuardianApplicationsIndex',
  components: {
    CampusHeader
  },
  data() {
    return {
      loaded: false,
      application_stage_text: [{
        name: 'New Application',
        text: 'Your application form has been received in good order and has been sent for processing. One of our dedicated team members will be in contact with you to confirm your application and explain the admissions process within 24-48 hours.'
      }, {
        name: 'Awaiting Payment',
        text: 'Upon receipt of your application, a non-refundable registration fee of R500 is required. We will make contact with you to confirm invoicing details as we take you through the admissions process.'
      }, {
        name: 'Paid',
        text: 'The non-refundable registration fee has now been paid, thank you. We will be in contact with you shortly regarding the spaces available for the starting date you have requested.'
      }, {
        name: 'Waiting List',
        text: 'Your application form has moved onto the waiting list. We will be in contact as soon as a position becomes available for enrolment.'
      }, {
        name: 'Observation',
        text: 'A position has become available at the campus and your application is no longer on the waiting list. We will be making contact with you in 24-48 hours to schedule an observation day for your child.'
      }, {
        name: 'Needs enrolment',
        text: 'Congratulations, we are glad to inform you that your application has been successful. Our admissions team will be in contact shortly to explain how to secure your position with Generation Schools.'
      }]
    }
  },
  methods: {
    stage_based_text(stage) {
      if (this.application_stage_text.some(stage_text => stage_text.name.toLowerCase() === stage.name.toLowerCase())) {
        return this.application_stage_text.filter(stage_text => stage_text.name.toLowerCase() === stage.name.toLowerCase())[0].text
      }
      return ''
    },
    goToCreate() {
      this.$router.push('/g/applications/create')
    },
    stageValue(application_stage_id) {
      return (
          ((this.stages.findIndex((x) => x.id === application_stage_id)) /
              this.stages.length) *
          100
      );
    },
  },
  computed: {
    stages() {
      return ApplicationStage.query().orderBy('order').get()
    },
    applications() {
      return Application.query().withAll().get()
    },
    user() {
      return User.query().whereId(this.$store.state.user.id).first()
    },
    headerText() {
      return `My Applications`;
    }
  },

  mounted() {
    this.$store.dispatch('loader/show')
    Application.deleteAll()
    let statusGet = ApplicationStatus.FetchAll()
    let stagesGet = ApplicationStage.FetchAll()
    let applicationGet = Application.FetchAll({
      page: 1,
      limit: 15
    }, {guardian_id: this.$store.state.user.id}, ['application_stage', 'user'])
    let userGet = User.FetchById(this.$store.state.user.id)
    Promise.all([applicationGet, userGet, stagesGet, statusGet]).then(() => {
      this.loaded = true
      this.$store.dispatch('loader/hide')

    })
  }
}
</script>
